import axios from 'axios';

import { IConsultantEducation } from '@/shared/model/consultant-education.model';

const baseApiUrl = 'api/consultant-educations';

export default class ConsultantEducationService {
	public find(id: number): Promise<IConsultantEducation> {
		return new Promise<IConsultantEducation>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public findByProfileId(profileId: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/byprofile/${profileId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public delete(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public create(entity: IConsultantEducation): Promise<IConsultantEducation> {
		return new Promise<IConsultantEducation>((resolve, reject) => {
			axios
				.post(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public update(entity: IConsultantEducation): Promise<IConsultantEducation> {
		return new Promise<IConsultantEducation>((resolve, reject) => {
			axios
				.put(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public partialUpdate(entity: IConsultantEducation): Promise<IConsultantEducation> {
		return new Promise<IConsultantEducation>((resolve, reject) => {
			axios
				.patch(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
