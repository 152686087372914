import { render, staticRenderFns } from "./backButton.vue?vue&type=template&id=15f507a9&scoped=true&"
import script from "./backButton.component.ts?vue&type=script&lang=ts&"
export * from "./backButton.component.ts?vue&type=script&lang=ts&"
import style0 from "./backButton.vue?vue&type=style&index=0&id=15f507a9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f507a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\workspace\\devport\\backend-repo\\src\\Backendrepo\\ClientApp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15f507a9')) {
      api.createRecord('15f507a9', component.options)
    } else {
      api.reload('15f507a9', component.options)
    }
    module.hot.accept("./backButton.vue?vue&type=template&id=15f507a9&scoped=true&", function () {
      api.rerender('15f507a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/shared/components/shared-buttons/backButton.vue"
export default component.exports