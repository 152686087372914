var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.modalId,
              expression: "modalId"
            }
          ],
          staticClass: "btn-group-btn",
          attrs: {
            type: "button",
            variant: "danger",
            "data-cy": "entityDeleteButton"
          },
          on: {
            click: function($event) {
              return _vm.prepareRemoveItem()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "btn-group-icon" },
            [
              _c("font-awesome-icon", {
                staticClass: "delete-icon",
                attrs: { icon: "trash" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDelete,
                  expression: "showDelete"
                }
              ],
              domProps: { textContent: _vm._s(_vm.$t("entity.action.delete")) }
            },
            [_vm._v("Delete")]
          )
        ]
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }