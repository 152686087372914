import { Authority } from "@/shared/security/authority";

const Register = () => import("@/account/register/register.vue");
const Activate = () => import("@/account/activate/activate.vue");
const ResetPasswordInit = () =>
    import("@/account/reset-password/init/reset-password-init.vue");
const ResetPasswordFinish = () =>
    import("@/account/reset-password/finish/reset-password-finish.vue");
const ChangePassword = () =>
    import("@/account/change-password/change-password.vue");
const Settings = () => import("@/account/settings/settings.vue");
const ProfileLee = () => import("@/account/profile/Profile.vue");


export default [
    {
        path: "/account/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/account/activate",
        name: "Activate",
        component: Activate,
    },
    {
        path: "/account/reset/request",
        name: "ResetPasswordInit",
        component: ResetPasswordInit,
    },
    {
        path: "/account/reset/finish",
        name: "ResetPasswordFinish",
        component: ResetPasswordFinish,
    },
    {
        path: "/account/password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: { authorities: [Authority.CONSULTANT] },
    },
    {
        path: "/account/settings",
        name: "Settings",
        component: Settings,
        meta: { authorities: [Authority.CONSULTANT, Authority.ADMIN, Authority.SUB_CONSULTANT, Authority.SUPER_ADMIN] },
    },
    {
        path: "/account/profile",
        name: "Profile",
        component: ProfileLee,
    },
];
