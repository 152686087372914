// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

// Errors
export const PROBLEM_BASE_URL = "https://www.jhipster.tech/problem";
export const EMAIL_ALREADY_USED_TYPE = PROBLEM_BASE_URL + "/email-already-used";
export const LOGIN_ALREADY_USED_TYPE = PROBLEM_BASE_URL + "/login-already-used";
