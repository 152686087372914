import axios from "axios";
import buildPaginationQueryOpts from "@/shared/sort/sorts";
import { IUser } from "@/shared/model/user.model";
import { and } from "vuelidate/lib/validators";

export default class UserManagementService {
  public get(login: string): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {axios.get(`api/admin/users/${login}`)                
          .then((res) => {
              if(!res.data.departmentId){
                res.data.department = undefined;
                res.data.departmentId = undefined; 
               }
               resolve(res.data);
          })
    });
  }

  public create(user): Promise<any> {
    return axios.post("api/admin/users", user);
  }

  public update(user): Promise<any> {
    return axios.put("api/admin/users", user);
  }

  public remove(userId: string): Promise<any> {
    return axios.delete(`api/admin/users/${userId}`);
  }

 
  public retrieve(req?: any): Promise<any> {
      var path = `api/admin/users?${buildPaginationQueryOpts(req)}`
      console.log(path)
      return axios.get(path);
  }

  public retrieveFiltered(req?: any): Promise<any> {
    let searchQuery = "";
    if(req.search){
      searchQuery = "&search=" + req.search;
    }
    var path = `api/admin/users/filtered-users?${buildPaginationQueryOpts(req)}` + searchQuery;
    console.log(path)
    return axios.get(path);
  } 

  public retrieveAuthorities(): Promise<any> {
    return axios.get("api/admin/users/authorities");
  }

  public retrieveConsultantProfiles(): Promise<any> {
      return axios.get("api/consultant-profiles");
  }

  public getHomePageUsers(filterObject: any): Promise<IUser> {
    //page=1&size=20&skillIds=2&skillIds=1&departmentIds=1&departmentIds=2
    let filterString = "";
    let andSign = "";
    if(filterObject.page >= 0){
      filterString += `${andSign}page=${filterObject.page}`;
      andSign = "&";
    }
    if(filterObject.size){
      filterString += `${andSign}size=${filterObject.size}`;
      andSign = "&";
    }
    if(filterObject.skillIds){
      for(var i = 0; i < filterObject.skillIds.length; i++)
      {
        filterString += `${andSign}skillIds=${filterObject.skillIds[i]}`;
        andSign = "&";
      }
    }
    if(filterObject.departmentIds){
      for(var i = 0; i < filterObject.departmentIds.length; i++)
      {
        filterString += `${andSign}departmentIds=${filterObject.departmentIds[i]}`;
        andSign = "&";
      }
    }
    return new Promise<IUser>((resolve, reject) => {
      axios.get(`api/admin/users/home-page?${filterString}`)                
          .then((res) => {
              
                resolve(res.data);
          })
    });
  }
}
