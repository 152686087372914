import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";

@Component
export default class JhiSortIndicatorComponent extends Vue {
  @Prop()
  currentOrder: string;
  @Prop()
  fieldName: string;
  @Prop()
  reverse: boolean;
}
