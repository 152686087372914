import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";

@Component
export default class editButton extends Vue {
@Prop()
name: string;
@Prop()
item: object;
@Prop()
params: string;
}