import axios from 'axios';

import { IConsultantProfile } from '@/shared/model/consultant-profile.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
const baseApiUrl = 'api/ConsultantProfile';

export default class ConsultantProfileService {
	public find(id: number): Promise<IConsultantProfile> {
		return new Promise<IConsultantProfile>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public findAllByUserId(userid: string, skillIdsSeperatedByComa): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/byUser`, { params: { skillIds: skillIdsSeperatedByComa, userid: userid } })
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public findbyId(Id: string): Promise<IConsultantProfile> {
		console.log('dsadsadsadsa');
		return new Promise<IConsultantProfile>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/byId/${Id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public delete(id: number): Promise<any> {
		console.log('hqqq');
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieveFiltered(req?: any): Promise<any> {
		let searchQuery = '';
		if (req.search) {
			searchQuery = '&search=' + req.search;
		}
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + '/filtered-consultant' + '?' + buildPaginationQueryOpts(req) + searchQuery)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public create(entity: IConsultantProfile): Promise<IConsultantProfile> {
		return new Promise<IConsultantProfile>((resolve, reject) => {
			axios
				.post(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
					console.log(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public update(entity: IConsultantProfile): Promise<IConsultantProfile> {
		return new Promise<IConsultantProfile>((resolve, reject) => {
			axios
				.put(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public partialUpdate(entity: IConsultantProfile): Promise<IConsultantProfile> {
		return new Promise<IConsultantProfile>((resolve, reject) => {
			axios
				.patch(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve1(req?: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + '?' + buildPaginationQueryOpts(req))
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
