var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "deleteModal",
      attrs: {
        "header-class": "modal-header-class",
        "footer-class": "modal-footer-class",
        "hide-header-close": "",
        centered: "",
        id: _vm.modalId,
        title: _vm.title
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-body text-center" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { type: "button" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
            on: { click: _vm.closeDialog }
          },
          [_vm._v("\n      Cancel\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", "data-cy": "entityConfirmDeleteButton" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.delete")) },
            on: { click: _vm.removeItem }
          },
          [_vm._v("\n      Delete\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }