import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";

@Component
export default class deleteButton extends Vue {
    @Prop()
  item: object;
  @Prop()
  modalId: string;
  @Prop()
  showDelete: boolean;

    public prepareRemoveItem(): void {
        this.$emit('prepare-remove', this.item);
        
      }
}