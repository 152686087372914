import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISkills } from '@/shared/model/skills.model';

const baseApiUrl = 'api/skills';

export default class SkillsService {
	public find(id: number): Promise<ISkills> {
		return new Promise<ISkills>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve(req?: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + `?${buildPaginationQueryOpts(req)}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieveFiltered(req?: any): Promise<any> {
		let searchQuery = '';
		if (req.search) {
			searchQuery = '&search=' + req.search;
		}
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + '/filtered-skills' + `?${buildPaginationQueryOpts(req)}` + searchQuery)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public delete(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public create(entity: ISkills): Promise<ISkills> {
		return new Promise<ISkills>((resolve, reject) => {
			axios
				.post(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public update(entity: ISkills): Promise<ISkills> {
		return new Promise<ISkills>((resolve, reject) => {
			axios
				.put(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public partialUpdate(entity: ISkills): Promise<ISkills> {
		return new Promise<ISkills>((resolve, reject) => {
			axios
				.patch(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
