import axios from 'axios';

import { IConsultantWorkExperience } from '@/shared/model/consultant-work-experience.model';

const baseApiUrl = 'api/consultant-work-experiences';

export default class ConsultantWorkExperienceService {
	public find(id: number): Promise<IConsultantWorkExperience> {
		return new Promise<IConsultantWorkExperience>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public findByProfileId(profileId: string, workExperience: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/byprofile/${profileId}/${workExperience}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public delete(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public create(entity: IConsultantWorkExperience): Promise<IConsultantWorkExperience> {
		console.log('qqqCREATED');
		return new Promise<IConsultantWorkExperience>((resolve, reject) => {
			axios
				.post(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public update(entity: IConsultantWorkExperience): Promise<IConsultantWorkExperience> {
		return new Promise<IConsultantWorkExperience>((resolve, reject) => {
			axios
				.put(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					console.log(res);
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public partialUpdate(entity: IConsultantWorkExperience): Promise<IConsultantWorkExperience> {
		return new Promise<IConsultantWorkExperience>((resolve, reject) => {
			axios
				.patch(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
