var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: {
      to: {
        name: _vm.name,
        params: _vm.params
      },
      custom: ""
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var navigate = ref.navigate
          return [
            _c(
              "button",
              {
                staticClass: "btn-group-btn",
                attrs: { "data-cy": "entityEditButton" },
                on: { click: navigate }
              },
              [
                _c(
                  "div",
                  { staticClass: "btn-group-icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "pencil-alt" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.edit"))
                    }
                  },
                  [_vm._v("Edit")]
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }