import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";

@Component
export default class deleteModal extends Vue {
@Prop()
modalId: string;
@Prop()
title: string;

public closeDialog(): void {
(<any>this.$refs.deleteModal).hide();
}

public removeItem() {
    this.closeDialog();
    this.$emit('remove-item');
}

}