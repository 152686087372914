import Vue from "vue";
import Component from "vue-class-component";
Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate", // for vue-router 2.2+
]);
import Router from "vue-router";

const Home = () => import("@/core/home/home.vue");
const Error = () => import("@/core/error/error.vue");
const ShowProfile = () => import("@/profile/profileshow/profile-show.vue")
const CreateProfile = () => import("@/profile/profilecreate/profile-create.vue")
const Consultants = () => import('@/entities/consultants/consultants.vue');
const ChangePassword = () =>
    import("@/account/change-password/change-password.vue");
import account from "@/router/account";
import admin from "@/router/admin";
import entities from "@/router/entities";
import ConsultantProfile from "@/router/ConsultantProfile";
import pages from "@/router/pages";



Vue.use(Router);

// prettier-ignore
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/forbidden',
            name: 'Forbidden',
            component: Error,
            meta: { error403: true }
        },
        {
            path: '/not-found',
            name: 'NotFound',
            component: Error,
            meta: { error404: true }
        },
        {
            path: "/department/profile/",
            name: "ShowProfile",
            component: ShowProfile,
        },
        {
            path: "/consultant/:departmentName/:login",
            name: "ShowProfile1",
            component: ShowProfile
        },
        {
            path: "/create-profile",
            name: "CreateProfile",
            component: CreateProfile
        },
        {
            path: "/consultants",
            name: "Consultants",
            component: Consultants
        },
        {
            path: "/account/password",
            name: "ChangePassword",
            component: ChangePassword,
            // meta: { authorities: [Authority.SUPER_ADMIN] },
        },
        ...account,
        ...admin,
        ...entities,
        ...ConsultantProfile,
        ...pages
    ]
});

router.beforeEach((to, from, next) => {
    // store is defined here
    // console.log(accountStore);
    next()
});


export default router;

