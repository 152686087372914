var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "footer",
      staticStyle: { display: "none" },
      attrs: { id: "footer" }
    },
    [
      _c("meta", { attrs: { charset: "ISO-8859-1" } }),
      _vm._v(" "),
      _c("p", { domProps: { textContent: _vm._s(_vm.$t("footer")) } }, [
        _vm._v("This is your footerasd")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { attrs: { id: "widgets", "data-widgets-layout": "3-3-3-3" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n            widget_text widget widget_custom_html\n            animate-me\n            fadeIn\n            col-md-3\n          ",
                attrs: { id: "custom_html-2" }
              },
              [
                _c("div", { staticClass: "textwidget custom-html-widget" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "http://intra.devport.se/wp-content/uploads/DevPort_logo_web_WO.png",
                      alt: "Devport_logo"
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("i", [
                    _vm._v(
                      "\n              På DevPort är vi stolta över våra medarbetare och våra kunder\n              som utvecklar framtidens teknik. För oss handlar det bl.a. om\n              laganda, gemenskap, korta beslutsvägar, erfarenhet,\n              entreprenörskap och stabilitet.\n            "
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "widget widget_text animate-me fadeIn col-md-3",
                attrs: { id: "text-16" }
              },
              [
                _c("div", { staticClass: "textwidget" }, [
                  _c("p", [
                    _c("strong", [_vm._v(" GÖTEBORG"), _c("br")]),
                    _vm._v("Theres Svenssons gata 10"),
                    _c("br"),
                    _vm._v("\n              417 55 Göteborg"),
                    _c("br"),
                    _vm._v("\n              031 – 50 59 00\n            ")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v(" LINKÖPING"), _c("br")]),
                    _vm._v("Teknikringen 10"),
                    _c("br"),
                    _vm._v("\n              583 30 Linköping"),
                    _c("br"),
                    _vm._v("\n              013 – 25 87 00\n            ")
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "widget widget_text animate-me fadeIn col-md-3",
                attrs: { id: "text-17" }
              },
              [
                _c("div", { staticClass: "textwidget" }, [
                  _c("p", [
                    _c("strong", [_vm._v(" STOCKHOLM"), _c("br")]),
                    _vm._v("Liljeholmsstranden 5"),
                    _c("br"),
                    _vm._v("\n              117 43 Stockholm"),
                    _c("br"),
                    _vm._v("\n              08 – 453 58 00"),
                    _c("strong", [_c("br")])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "widget widget_text animate-me fadeIn col-md-3",
                attrs: { id: "text-15" }
              },
              [
                _c("h3", [_vm._v("Organisationen")]),
                _vm._v(" "),
                _c("div", { staticClass: "textwidget" }, [
                  _c("p", [
                    _vm._v("\n              DevPorts\n              "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://intra.devport.se/organisationen/"
                        }
                      },
                      [_vm._v("ledare och stab.")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("\n              Kontaktuppgifter till DevPorts"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://intra.devport.se/aktivitetsgruppen/"
                        }
                      },
                      [_vm._v("\n                aktivitetsgrupper")]
                    ),
                    _vm._v(".\n            ")
                  ])
                ])
              ]
            )
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "copyright" } }, [
      _c("p", [_vm._v("© all rights reserved.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }