import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";

@Component
export default class deleteButton extends Vue {
    @Prop()
  name: string;

  @Prop()
  text: string;
    
}