import { Authority } from "@/shared/security/authority";
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const Department = () => import('@/entities/department/department.vue');
// prettier-ignore
const DepartmentUpdate = () => import('@/entities/department/department-update.vue');
// prettier-ignore
const DepartmentDetails = () => import('@/entities/department/department-details.vue');

// prettier-ignore
const Skills = () => import('@/entities/skills/skills.vue');
// prettier-ignore
const SkillsUpdate = () => import('@/entities/skills/skills-update.vue');
// prettier-ignore
const SkillsDetails = () => import('@/entities/skills/skills-details.vue');

// prettier-ignore
const Consultants = () => import('@/entities/consultants/consultants.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
    {
        path: "/department",
        name: "Department",
        component: Department,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/department/new",
        name: "DepartmentCreate",
        component: DepartmentUpdate,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/department/:departmentId/edit",
        name: "DepartmentEdit",
        component: DepartmentUpdate,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/department/:departmentId/view",
        name: "DepartmentView",
        component: DepartmentDetails,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },

    },

    {
        path: "/skills",
        name: "Skills",
        component: Skills,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/skills/new",
        name: "SkillsCreate",
        component: SkillsUpdate,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/skills/:skillsId/edit",
        name: "SkillsEdit",
        component: SkillsUpdate,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/skills/:skillsId/view",
        name: "SkillsView",
        component: SkillsDetails,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },
    {
        path: "/consultants",
        name: "ConsultantsView",
        component: Consultants,
        meta: { authorities: [Authority.SUPER_ADMIN, Authority.ADMIN] },
    },

    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
