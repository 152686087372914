import axios from "axios";
import { accountStore } from "./store/account-store"
import { Inject, Vue } from "vue-property-decorator";

import { SERVER_API_URL } from "@/constants";

const TIMEOUT = 1000000;
const onRequestSuccess = (config) => {
  console.log('axios 1');
  accountStore.state.customLoader = false; 
  accountStore.state.customLoader = true;
  const token =
  localStorage.getItem("jhi-authenticationToken") ||
  sessionStorage.getItem("jhi-authenticationToken");
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  console.log('axios 2');
  return config;
};
const setupAxiosInterceptors = (onUnauthenticated) => {
  const onResponseError = (err) => {
    const status = err.status || err.response.status;
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use((res) => res, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
