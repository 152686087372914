import Vue from "vue";
import Component from "vue-class-component";
import Ribbon from "@/core/ribbon/ribbon.vue";
import JhiFooter from "@/core/jhi-footer/jhi-footer.vue";
import JhiNavbar from "@/core/jhi-navbar/jhi-navbar.vue";
import LoginForm from "@/account/login-form/login-form.vue";
import ProgressBar from './core/progressbar/progressbar.vue';

import "@/shared/config/dayjs";

@Component({
  components: {
    ribbon: Ribbon,
    "jhi-navbar": JhiNavbar,
    "login-form": LoginForm,

    "jhi-footer": JhiFooter,
    "ProgressBar": ProgressBar,
  },
})
export default class App extends Vue {
  public customLoader: boolean = false;
  
  public mounted() {
		this.customLoader = this.$store.getters.customLoader;
  }
}
