// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import App from "./app.vue";
import Vue2Filters from "vue2-filters";
import { ToastPlugin } from "bootstrap-vue";
import router from "./router";
import * as config from "./shared/config/config";
import * as bootstrapVueConfig from "./shared/config/config-bootstrap-vue";
import JhiItemCountComponent from "./shared/jhi-item-count.vue";
import JhiSortIndicatorComponent from "./shared/sort/jhi-sort-indicator.vue";
import InfiniteLoading from "vue-infinite-loading";
import HealthService from "./admin/health/health.service";
import MetricsService from "./admin/metrics/metrics.service";
import LogsService from "./admin/logs/logs.service";
import ConfigurationService from "@/admin/configuration/configuration.service";
import ActivateService from "./account/activate/activate.service";
import RegisterService from "./account/register/register.service";
import UserManagementService from "@/admin/user-management/user-management.service";
import LoginService from "./account/login.service";
import AccountService from "./account/account.service";


import "../content/scss/vendor.scss";
import TranslationService from "@/locale/translation.service";

import UserOAuth2Service from "@/entities/user/user.oauth2.service";
/* tslint:disable */

import DepartmentService from "@/entities/department/department.service";
import UserDepartmentsService from "@/entities/user-departments/user-departments.service";
import ConsultantProfileService from "@/profile/profilecreate/consultant-profile.service";
import SkillsService from "@/entities/skills/skills.service";
import ConsultantSkillsService from "@/entities/consultant-skills/consultant-skills.service";
import ConsultantDescriptionService from "@/entities/consultant-description/consultant-description.service";
import ExperienceSkillsService from "@/entities/experience-skills/experience-skills.service";
import ConsultantWorkExperienceService from "@/entities/consultant-work-experience/consultant-work-experience.service";
import ConsultantEducationService from "@/entities/consultant-education/consultant-education.service";
import profileshow from "@/profile/profileshow/profile-show.component";
import profilecreate from "@/profile/profilecreate/profile-create.component";
import DeleteButtonComponent from "@/shared/components/shared-buttons/deleteButton.vue";
import EditButtonComponent from "@/shared/components/shared-buttons/editButton.vue";
import CreateButtonComponent from "@/shared/components/shared-buttons/createButton.vue";
import BackButtonComponent from "@/shared/components/shared-buttons/backButton.vue";
import DeleteModalComponent from "@/shared/components/shared-modals/deleteModal.vue";
import searchCreateComponent from "@/shared/components/shared-headers/searchCreate.vue";


//import experienceComponent from "@/profile/profilecreate/components/experience-component.vue"


/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("jhi-item-count", JhiItemCountComponent);
Vue.component("jhi-sort-indicator", JhiSortIndicatorComponent);
Vue.component("infinite-loading", InfiniteLoading);
Vue.component("Delete-Button-Component", DeleteButtonComponent);
Vue.component("Edit-Button-Component", EditButtonComponent);
Vue.component("Create-Button-Component", CreateButtonComponent);
Vue.component("Delete-Modal-Component", DeleteModalComponent);
Vue.component("Back-Button-Component", BackButtonComponent);
Vue.component("Search-Create-Component", searchCreateComponent);
// Vue.component("Experience-Component", experienceComponent)

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, router);

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next("/not-found");
    }

    if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
        accountService
            .hasAnyAuthorityAndCheckAuth(to.meta.authorities)
            .then((value) => {
                if (!value) {
                    sessionStorage.setItem("requested-url", to.fullPath);
                    next("/forbidden");
                } else {
                    next();
                }
            });
    } else {
        // no authorities, so just proceed
        next();
    }
});

/* tslint:disable */
new Vue({
    el: "#app",
    components: { App },
    template: "<App/>",
    router,
    provide: {
        loginService: () => loginService,
        activateService: () => new ActivateService(),
        registerService: () => new RegisterService(),
        userService: () => new UserManagementService(),
        healthService: () => new HealthService(),
        configurationService: () => new ConfigurationService(),
        logsService: () => new LogsService(),
        metricsService: () => new MetricsService(),

        userOAuth2Service: () => new UserOAuth2Service(),
        translationService: () => translationService,
        departmentService: () => new DepartmentService(),
        userDepartmentsService: () => new UserDepartmentsService(),
        consultantProfileService: () => new ConsultantProfileService(),
        skillsService: () => new SkillsService(),
        consultantSkillsService: () => new ConsultantSkillsService(),
        consultantDescriptionService: () => new ConsultantDescriptionService(),
        consultantWorkExperienceService: () =>
            new ConsultantWorkExperienceService(),
        experienceSkillsService: () => new ExperienceSkillsService(),
        consultantEducationService: () => new ConsultantEducationService(),
        accountService: () => accountService,
    },
    i18n,
    store,
});
