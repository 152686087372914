import Component from "vue-class-component";
import {Vue , Prop} from "vue-property-decorator";

@Component
export default class searchCreate extends Vue {

@Prop()
pageTitle: string;
@Prop()
name: string;
@Prop()
text: string;
@Prop()
placeHolder: string;


public searchQuery: string = '';

public updateSearch() : void {
    this.$emit('update-search', this.searchQuery)
}

}