var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "heading-wrapper",
      attrs: { id: "page-heading", "data-cy": "SkillsHeading" }
    },
    [
      _c(
        "div",
        { staticClass: "heading" },
        [
          _c("h4", {
            staticClass: "page-title",
            domProps: { textContent: _vm._s(_vm.pageTitle) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-field" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery"
                  }
                ],
                attrs: { type: "search", placeholder: _vm.placeHolder },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: _vm.updateSearch,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "search-icon",
                attrs: { icon: "search" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("Create-Button-Component", {
            staticClass: "create-btn",
            attrs: { name: _vm.name, text: _vm.text }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }