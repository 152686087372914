import { Module } from "vuex";

export const accountStore: Module<any, any> = {
    state: {
        logon: false,
        userIdentity: null,
        authenticated: false,
        ribbonOnProfiles: "",
        activeProfiles: "",
        consultantList: [],
        showProfileFirstName: "",
        showProfileLastName: "",
        customLoader: true,

    },
    getters: {
        logon: (state) => state.logon,
        account: (state) => state.userIdentity,
        authenticated: (state) => state.authenticated,
        activeProfiles: (state) => state.activeProfiles,
        ribbonOnProfiles: (state) => state.ribbonOnProfiles,
        showProfileFirstName: (state) => state.showProfileFirstName,
        showProfileLastName: (state) => state.showProfileLastName,
        customLoader: (state) => state.customLoader,
    },
    mutations: {
        customLoader(state, isLoading) {
            
            state.customLoader = isLoading;
        },

        authenticate(state) {
            state.logon = true;
        },
        authenticated(state, identity) {
            state.userIdentity = identity;
            state.authenticated = true;
            state.logon = false;
        },
        logout(state) {
            state.userIdentity = null;
            state.authenticated = false;
            state.logon = false;
        },
        setActiveProfiles(state, profile) {
            state.activeProfiles = profile;
        },
        setRibbonOnProfiles(state, ribbon) {
            state.ribbonOnProfiles = ribbon;
        },
        setSelectedUserProfile(state, user) {
            //console.log(user.selectedUser.firstName)
            state.showProfileFirstName = user.selectedUser.firstName
            state.showProfileLastName = user.selectedUser.lastName
        },
        setConsultantList(state, cList) {
            state.consultantList = cList
        }
    },
};

