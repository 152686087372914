var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: { to: { name: _vm.name }, custom: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var navigate = ref.navigate
          return [
            _c(
              "button",
              {
                staticClass: "create-btn jh-create-entity",
                attrs: {
                  id: "jh-create-entity",
                  "data-cy": "entityCreateButton"
                },
                on: { click: navigate }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "create-new",
                    domProps: { textContent: _vm._s(_vm.text) }
                  },
                  [_vm._v(" Create New ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "plus-btn" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "plus-icon",
                      attrs: { icon: "plus" }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }