var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-body", attrs: { id: "myModal" } }, [
    _c("div", [
      _c(
        "div",
        [
          _vm.authenticationError
            ? _c(
                "b-alert",
                {
                  attrs: {
                    show: "",
                    "data-cy": "loginError",
                    variant: "danger"
                  },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("login.messages.error.authentication")
                    )
                  }
                },
                [
                  _c("strong", [_vm._v("Failed to sign in!")]),
                  _vm._v(
                    " Please check your credentials and\n        try again.\n      "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "b-form",
            {
              attrs: { role: "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.doLogin()
                }
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("global.form['email.label']"),
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    staticClass: "email",
                    attrs: {
                      id: "email",
                      type: "text",
                      name: "email",
                      autofocus: "",
                      placeholder: _vm.$t("global.form['email.placeholder']"),
                      "data-cy": "email"
                    },
                    model: {
                      value: _vm.login,
                      callback: function($$v) {
                        _vm.login = $$v
                      },
                      expression: "login"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("login.form.password"),
                    "label-for": "password"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password",
                      type: "password",
                      name: "password",
                      placeholder: _vm.$t("login.form['password.placeholder']"),
                      "data-cy": "password"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "remember-me",
                  attrs: { id: "rememberMe", name: "rememberMe", checked: "" },
                  model: {
                    value: _vm.rememberMe,
                    callback: function($$v) {
                      _vm.rememberMe = $$v
                    },
                    expression: "rememberMe"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(_vm.$t("login.form.rememberme"))
                      }
                    },
                    [_vm._v("Remember me")]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row justify-content-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-primary sign-in-button",
                      attrs: {
                        "data-cy": "submit",
                        type: "submit",
                        variant: "primary"
                      },
                      domProps: {
                        textContent: _vm._s(_vm.$t("login.form.button"))
                      }
                    },
                    [_vm._v("SIGN IN")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row justify-content-center" },
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "", variant: "warning" } },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "row justify-content-center alert-link",
                          attrs: {
                            to: "/account/reset/request",
                            "data-cy": "forgetYourPasswordSelector"
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("login.password.forgot"))
                          }
                        },
                        [_vm._v("Did you forget your password?")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row justify-content-center no-account" },
            [
              _c(
                "b-alert",
                { attrs: { show: "", variant: "warning" } },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.info.register.noaccount")
                        )
                      }
                    },
                    [_vm._v("You don't have an account yet?")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-link",
                    {
                      staticClass: "alert-link2",
                      attrs: { to: "/account/register" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.info.register.link")
                        )
                      },
                      on: { click: _vm.hideModal }
                    },
                    [_vm._v("Register a new account")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }