import axios from 'axios';

import { IDepartment } from '@/shared/model/department.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'api/departments';

export default class DepartmentService {
	public find(id: number): Promise<IDepartment> {
		return new Promise<IDepartment>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/${id}`)
				.then((res) => {
					if (!res.data.description) {
						res.data.description = undefined;
					}
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public findByDepartmentUniqueName(departmentUniqueName: string): Promise<IDepartment> {
		return new Promise<IDepartment>((resolve, reject) => {
			axios
				.get(`${baseApiUrl}/uniqueName/${departmentUniqueName}`)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieve(req?: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + '?' + buildPaginationQueryOpts(req))
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public retrieveFiltered(req?: any): Promise<any> {
		let searchQuery = '';
		if (req.search) {
			searchQuery = '&search=' + req.search;
		}
		return new Promise<any>((resolve, reject) => {
			axios
				.get(baseApiUrl + '/filtered-departments' + '?' + buildPaginationQueryOpts(req) + searchQuery)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public delete(id: number): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(`${baseApiUrl}/${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public create(entity: IDepartment): Promise<IDepartment> {
		return new Promise<IDepartment>((resolve, reject) => {
			axios
				.post(`${baseApiUrl}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public update(entity: IDepartment): Promise<IDepartment> {
		return new Promise<IDepartment>((resolve, reject) => {
			axios
				.put(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public partialUpdate(entity: IDepartment): Promise<IDepartment> {
		return new Promise<IDepartment>((resolve, reject) => {
			axios
				.patch(`${baseApiUrl}/${entity.id}`, entity)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
