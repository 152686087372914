import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const profileshow = () => import('@/profile/profileshow/profile-show.vue');

// prettier-ignore
const ProfileCreate = () => import('@/profile/profilecreate/profile-create.vue');
const UserProfileView = () => import('@/profile/profileView/user-profile-view.vue');
const UserProfileEdit = () => import('@/profile/profileEdit/profile-edit.vue');

export default [
	{
		path: '/profilesshow/:consultantProfileId',
		name: 'profileshow',
		component: profileshow,
		meta: { authorities: [ Authority.SUPER_ADMIN, Authority.CONSULTANT, Authority.ADMIN ] }
	},
	{
		path: '/profile-create',
		name: 'ProfileCreate',
		component: ProfileCreate,
		meta: { authorities: [ Authority.SUPER_ADMIN, Authority.CONSULTANT, Authority.ADMIN ] }
	},
	{
		path: '/profileview/:consultantId',
		name: 'UserProfileView',
		component: UserProfileView,
		meta: {
			authorities: [
				Authority.CONSULTANT,
				Authority.SUB_CONSULTANT,
				Authority.SUPER_ADMIN,
				Authority.CONSULTANT,
				Authority.ADMIN
			]
		}
	},
	{
		path: '/profile-edit/:consultantId',
		name: 'UserProfileEdit',
		component: UserProfileEdit,
		meta: {
			authorities: [
				Authority.CONSULTANT,
				Authority.SUB_CONSULTANT,
				Authority.SUPER_ADMIN,
				Authority.CONSULTANT,
				Authority.ADMIN
			]
		}
	}
];
