import { Component, Inject, Vue } from "vue-property-decorator";
import { VERSION } from "@/constants";
import LoginService from "@/account/login.service";
import AccountService from "@/account/account.service";
import TranslationService from "@/locale/translation.service";

import { IConsultantProfile } from "@/shared/model/consultant-profile.model"

@Component
export default class JhiNavbar extends Vue {
    @Inject("loginService")
    private loginService: () => LoginService;
    @Inject("translationService")
    private translationService: () => TranslationService;

    @Inject("accountService") private accountService: () => AccountService;
    public version = VERSION ? "v" + VERSION : "";


    private currentLanguage = this.$store.getters.currentLanguage;
    private languages: any = this.$store.getters.languages;
    private hasAnyAuthorityValue = false;
    private userProfiles: IConsultantProfile[] = [];
    public stopScroll: Boolean = false;
    public expanded: Boolean = false;


    created() {
        this.translationService().refreshTranslation(this.currentLanguage);
        window.addEventListener('resize', this.reportWindowSize);
        document.addEventListener('click', this.isExpanded);
    }

    mounted() {
    }

    public isExpanded() {        
        const hamburger = document.getElementById('hamburger');
        const expanded = hamburger.getAttribute('aria-expanded')
        if (expanded === 'true') {
            this.stopScroll = true;
            (<HTMLElement>document.querySelector('body')).style.overflow = "hidden"
        }
        else {
            this.stopScroll = false;
            (<HTMLElement>document.querySelector('body')).style.overflow = "auto"
        }
        
    }

    public reportWindowSize() {
        const hamburger = document.getElementById('hamburger');
        const expanded = hamburger.getAttribute('aria-expanded')
        
        if (expanded === 'true') {
            this.stopScroll = true;
            (<HTMLElement>document.querySelector('body')).style.overflow = "hidden"
        }
        else {
            this.stopScroll = false;
            (<HTMLElement>document.querySelector('body')).style.overflow = "auto"
        }
    }

    public stopScrollMobile() {
        this.stopScroll = !this.stopScroll;
        if (this.stopScroll === true) {
            (<HTMLElement>document.querySelector('body')).style.overflow = "hidden"
            return
        }
        (<HTMLElement>document.querySelector('body')).style.overflow = "auto"
    }

    public subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input];
        return paths.some((path) => {
            return this.$route.path.indexOf(path) === 0; // current path starts with this path string
        });
    }

    public goToProfile(route): void {
        this.$router.push(route);
    }

    public changeLanguage(newLanguage: string): void {
        this.translationService().refreshTranslation(newLanguage);
    }

    public isActiveLanguage(key: string): boolean {
        return key === this.$store.getters.currentLanguage;
    }

    public prepareLogout(): void {
        console.log("logouti")
        // localStorage.removeItem("jhi-authenticationToken");
        // sessionStorage.removeItem("jhi-authenticationToken");
        // this.$store.commit("logout");
        // this.$router.push("/", () => { });
    }

    public logout(): void {
        localStorage.removeItem("jhi-authenticationToken");
        sessionStorage.removeItem("jhi-authenticationToken");
        this.$store.commit("logout");
        this.$router.push("/", () => { });
    }

    public openLogin(): void {

        this.loginService().openLogin((<any>this).$root);
    }

    public get authenticated(): boolean {
        return this.$store.getters.authenticated;
    }

    public hasAnyAuthority(authorities: any): boolean {
        this.accountService()
            .hasAnyAuthorityAndCheckAuth(authorities)
            .then((value) => {

                this.hasAnyAuthorityValue = value;
            });
        return this.hasAnyAuthorityValue;
    }

    public get openAPIEnabled(): boolean {
        return this.$store.getters.activeProfiles.indexOf("api-docs") > -1;
    }

    public get inProduction(): boolean {
        return this.$store.getters.activeProfiles.indexOf("prod") > -1;
    }

    public get profiles(): IConsultantProfile[] {
        return this.$store.getters.account ? this.$store.getters.account.consultantProfile : "";
    }
}
